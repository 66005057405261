<template>
    <div class="card market-info-block">
        <div class="card-market-box">
            <div class="card-market-header" style="margin-bottom: 6px;">
                <div class="card-market-title">
                    <span>{{ $t('market_blocks.headers.trending') }}</span>
                </div>
                <div class="card-market-period">
                    <span>24h</span>
                </div>
            </div>
            <div class="card-market-body" v-if="jettons">
                <trending-item
                    v-for="(jetton, index) in jettons" v-bind:key="index"
                    v-bind:img="jetton.img"
                    v-bind:name="jetton.name"
                    v-bind:price="jetton.price"
                    v-bind:symbol="jetton.symbol"
                    v-bind:jetton="jetton.jetton"
                    v-bind:change="jetton.day_change"
                />
            </div>
            <trending-skeleton v-else />
        </div>
    </div>
</template>

<script>
// import MiniChart from '~/lib/Chart.js/UiChartMini.vue';
// import { getTrendingJettonsInfo } from '~/api/jettonIndexer.js';
// import { prefixNumber } from '~/lib/Chart.js/helpers.js';
import TrendingItem from './TrendingItem.vue';
import TrendingSkeleton from './TrendingSkeleton.vue';
import { getJettons } from '../../../../api/jettoncat.js';

export default {
    data() {
        return {
            jettons: undefined,
        };
    },

    mounted() {
        this.getTrendingInfo();
    },

    methods: {
        async getTrendingInfo() {
            try {
                const limit = 3;
                const offset = 0;
                const response = await getJettons({ limit, offset });

                const data = response.map(jetton => Object.freeze({
                    day_change: jetton.price_changes.day,
                    id: jetton.id,
                    img: jetton.img,
                    jetton: jetton.address,
                    name: jetton.name,
                    price: jetton.price,
                    symbol: jetton.symbol,
                }));

                this.jettons = data;
            } catch (error) {
                console.error(error);
            }
        },
    },

    components: {
        // MiniChart,
        TrendingItem,
        TrendingSkeleton,
    },
};
</script>

<style lang="scss">
.card-market-jetton:hover {
    color: var(--body-text-color);
    text-decoration: none;
}

.card-market-body {
    width: 100%;
}

.card-market-jetton:first-child {

}

.card-market-jetton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--body-text-color);
    margin: 5px 6px;
    padding: 10px 9px;
    border-radius: 10px;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    .card-market-jetton-item {
        display: flex;
        align-items: center;
    }
    .card-market-jetton-image {
        width: 24px;
        height: 24px;
        padding-right: 12px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    .card-market-jetton-name {
        font-weight: 500;
    }
    .card-market-jetton-price {
        margin-left: 20px;
        font-weight: 500;
    }
    .card-jetton-green {
        color: #3fb950;
    }
    .card-jetton-red {
        color: #f74b4c;
    }
}

.card-market-jetton:last-child {
    margin-bottom: 8px;
}

.card-market-jetton:hover {
    background: var(--body-light-muted-color);
}
@media screen and (max-width: 599px) {

}
</style>
