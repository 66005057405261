<template>
    <div class="price-change-indicator" v-bind:class="`price-change-indicator--${color}`">
        {{ sign }}{{ value }}
    </div>
</template>

<script>
export default {
    props: {
        change: undefined,
    },
    computed: {
        color() {
            if (this.change == null) return 'gray';
            return this.change < 0 ? 'red' : 'green';
        },
        sign() {
            if (this.change == null) {
                return '';
            }
            return this.change < 0 ? '−' : '+';
        },
        value() {
            if (this.change == null) return '−';
            let percents = Math.abs(this.change * 100).toFixed(2);
            if (percents > 100) percents = 99.9;
            return `${percents}%`;
        },
    },
};
</script>

<style lang="scss">
.price-change-indicator {
  &--red {
    color: #f74b4c;
  }

  &--green {
    color: #3fb950;
  }

  &--gray {
    color: #888888;
  }
}
</style>
