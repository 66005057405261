<template>
    <div class="jetton-stats">
        <!-- <div class="jetton-stats-row" v-if="!emptyData">
            <div class="jetton-stats-row__name">{{ $t('jetton.volume') }}</div>
            <div class="jetton-stats-row__value">
                <span v-if="volume">
                    $ {{ volume }}
                </span>
                <span v-else class="skeleton">$12,345.67</span>
            </div>
        </div> -->

        <div class="jetton-stats-row">
            <div class="jetton-stats-row__name">{{ $t('jetton.market_cap') }}</div>
            <div class="jetton-stats-row__value">
                <span v-if="marketCap || marketCap === 0">
                    {{ currencySymbol }} {{ formatMarket(marketCap) }}
                </span>
                <span v-else class="skeleton">$12,345.678</span>
            </div>
        </div>

        <div class="jetton-stats-row">
            <div class="jetton-stats-row__name">{{ $t('jetton.holders') }}</div>
            <div class="jetton-stats-row__value">
                <ui-link v-if="holders" v-bind:to="{ name: 'jetton', hash: '#holders', params: { address: address }}">{{ formatNumberWithSpaces(holders) }}</ui-link>
                <span v-else class="skeleton">1234</span>
            </div>
        </div>

        <div class="jetton-stats-row">
            <div class="jetton-stats-row__name">{{ $t('jetton.supply') }}</div>
            <div class="jetton-stats-row__value">
                <span v-if="total_supply !== undefined">
                    {{ formattedTotalSupply }} {{ symbol }}
                </span>
                <span v-else class="skeleton skeleton--inline">1000000 TOKENS</span>
            </div>
        </div>

        <div class="jetton-stats-row">
            <div class="jetton-stats-row__name">{{ $t('jetton.mutable') }}</div>
            <div class="jetton-stats-row__value">
                <span v-if="is_mutable !== undefined">
                    {{ is_mutable ? $t('common.yes') : $t('common.no') }}
                </span>
                <span v-else class="skeleton skeleton--inline">Maybe</span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        address: String,
        volume: Number,
        total_supply: Number,
        holders: Number,
        price: Number,
        decimals: Number,
        emptyData: Boolean,
        symbol: String,
        marketCap: Number,
        is_mutable: Boolean,
    },

    // computed: {
    //     formattedTotalSupply() {
    //         const value = this.$ton(this.total_supply, this.decimals).replace(/\s/g, '');
    //         return this.formatAndLocalizeNumber(value);
    //     },
    // },

    computed: {
        formattedTotalSupply() {
            const value = this.$ton(this.total_supply, this.decimals).replace(/\s/g, '');
            const localizedNumber = this.formatAndLocalizeNumber(value);

            return localizedNumber.replace(/,00$/, '');
        },
        currentCurrency() {
            return this.$store.state.exchangeRateCurrency;
        },
        currencySymbol() {
            const symbols = { usd: '$', rub: '₽', aed: 'د.إ' };
            return symbols[this.currentCurrency.toLowerCase()] || '';
        },
    },

    methods: {
        formatMarket(value) {
            const number = new Intl.NumberFormat('ru-RU', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(value);

            return number;
        },

        formatAndLocalizeNumber(value) {
            const number = parseFloat(value).toFixed(2);

            return number.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },

        formatNumberWithSpaces(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
    },
};
</script>
