<template>
    <div class="card market-info-block">
        <!-- <ChartVolume v-if="isTablet || isMobile"/> -->
        <!-- <agile
            v-bind:navButtons="false"
            v-bind:dots="true"
            v-bind:autoplay="true"
            v-bind:autoplaySpeed="9000"
            v-bind:clientWidth="100"
            v-else
        >
            <ChartMarketcap />
            <ChartVolume />
            <ChartTVL />
        </agile -->
        <ChartMarketcap />
    </div>
</template>

<script>
// import ChartVolume from './chartVolume.vue';
import ChartMarketcap from './chartMarketcap.vue';
// import ChartTVL from './chartTVL.vue';
// import { VueAgile } from 'vue-agile';

export default {
    components: {
        // ChartVolume,
        ChartMarketcap,
        // ChartTVL,
        // agile: VueAgile,
    },
};
</script>

<style lang="scss">
.agile {
    display: flex;
    flex-direction: column;

    &__actions {
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 2;
    }
    &__slides {
        align-items: flex-start;
    }
    &__slide {
        // opacity: 0;
    }
    &__slide--active {
        // opacity: 1;
    }
    &__list {
        order: 2;
    }

    &__dots {
        margin: 0;
        gap: 5px;
        padding: 15px 15px 5px 75px;
        background: var(--dots-slider);
        border-radius: 0 12px 0 0;
    }

    &__dot button {
        border-radius: 50px;
        height: 4px;
        width: 15px;
        padding: 8px 0;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        border: 0;
        position: relative;
        cursor: pointer;
    }

    &__dot button:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 4px;
        border-radius: 50px;
        transform: translate(0%,-50%);
        background: var(--button-slider-opacity);
    }

    &__dot--current button {
        width: 25px;
        ::after {
            background: var(--button-slider);
        }
    }

    &__dot--current button:after {
        background: var(--button-slider);
    }
}

.card-market-body {
    width: 100%;
}

.card-market-cap {
    padding: 12px 16px 0px 16px;
    font-size: 20px;
}

.chart-bar-mini__graph{
    max-height: 160px;
    height: 104px;
}
@media screen and (max-width: 599px) {

}
</style>
