<template>
    <div class="app-header-dropdown">
        <div class="app-header-dropdown__container">
            <div class="app-header-dropdown__items">
                <div class="app-header-dropdown__item"
                    v-for="link in links"
                    v-bind:key="`header_link_${link}`"
                    v-bind:class="{ 'app-header-dropdown__item-active': $route.name.startsWith(link) || (link === 'apps' && $route.name === 'app') }">
                    <ui-link class="app-header-dropdown__anchor" v-bind:to="{ name: link }">
                        {{$t(`header.links.${link}`)}}
                    </ui-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        links() {
            return [
                'blocks',
                'whales',
                'validators',
                'config',
            ];
        },
    },
};
</script>

<style lang="scss">
.app-header-dropdown {
    position: absolute;
    top: 50px;
    left: -12px;
    z-index: 1000;

    &__container {
        background: var(--card-background);
        border: 1px solid var(--card-border-color);
        box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
        border-radius: 12px;
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding: 8px;
    }

    &__item:first-child &__anchor {
        padding: 12px 13px 10px 13px;
    }

    &__item &__anchor {
        display: block;
        padding: 10px 13px;
        border-radius: 8px;
        white-space: nowrap;
    }

    &__item &__anchor:hover, &__item-active &__anchor {
        background: var(--body-light-muted-color);
    }

    &__item:last-child &__anchor {
        padding: 10px 13px 12px 13px;
    }
}
</style>
