<template>
    <div class="card-market-body">
        <div class="card-market-jetton">
            <div class="card-market-jetton-item">
                <div class="card-market-jetton-image">
                    <div class="skeleton">NN</div>
                </div>
                <div class="card-market-jetton-name">
                    <div class="skeleton">NNNNNNNNNNN</div>
                </div>
            </div>
            <div class="card-market-jetton-item">
                <div class="skeleton">NNNN</div>
                <div class="card-market-jetton-price">
                    <div class="skeleton">NNNNN</div>
                </div>
            </div>
        </div>
        <div class="card-market-jetton">
            <div class="card-market-jetton-item">
                <div class="card-market-jetton-image">
                    <div class="skeleton">NN</div>
                </div>
                <div class="card-market-jetton-name">
                    <div class="skeleton">NNNNNNNNNNN</div>
                </div>
            </div>
            <div class="card-market-jetton-item">
                <div class="skeleton">NNNN</div>
                <div class="card-market-jetton-price">
                    <div class="skeleton">NNNNN</div>
                </div>
            </div>
        </div>
        <div class="card-market-jetton">
            <div class="card-market-jetton-item">
                <div class="card-market-jetton-image">
                    <div class="skeleton">NN</div>
                </div>
                <div class="card-market-jetton-name">
                    <div class="skeleton">NNNNNNNNNNN</div>
                </div>
            </div>
            <div class="card-market-jetton-item">
                <div class="skeleton">NNNN</div>
                <div class="card-market-jetton-price">
                    <div class="skeleton">NNNNN</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="scss">
.card-market-jetton-image {
    width: 35px;
    height: 35px;
}
.card-market-jetton-image .skeleton {
    height: 100%;
}
</style>
