<!-- <template>
    <tr>
        <td>
            <ui-link v-bind:to="{ name: 'jetton', params: { address: wallet.jetton_address }}" class="jetton-list-link">
                <ui-round-image class="jetton-list__icon" size="tiny" v-bind:src="wallet.jetton_meta.image.w72"/>
                {{ wallet.jetton_meta.name || wallet.jetton_address }}
            </ui-link>
        </td>
        <td>
            <b>{{ $ton(wallet.balance, wallet.jetton_meta.decimals) }}</b>
            <span style="margin-left: 4px; opacity: .5">{{ wallet.jetton_meta.symbol }}</span>
        </td>
        <td class="ui-table__cell--align-right">
            <ui-address v-bind:address="wallet.address"/>
        </td>
        <td class="ui-table__cell--align-right price-token-table">
            <ui-format-price v-bind:price="getJettonRate(wallet) || 0" v-bind:symbol="getSymbolStore()"/>
        </td>
    </tr>
</template>

<script>
import { formatTons } from '~/helpers.js';

export default {
    props: {
        wallet: Object,
        rates: Object,
    },

    methods: {
        getJettonRate(wallet) {
            const jettonsCount = parseFloat(formatTons(wallet.balance, wallet.jetton_meta.decimals).replace(/\s/g, '').replace(',', '.'));
            const currencyTicker = this.$store.state.exchangeRateCurrency.toUpperCase();

            const rate = this.rates[wallet.jetton_address]?.prices[currencyTicker];

            if (rate !== undefined) {
                return rate * jettonsCount;
            }

            return null;
        },

        getSymbolStore() {
            const symbolStore = this.$store.state.exchangeRateCurrency;

            switch (symbolStore.toLowerCase()) {
                case 'rub':
                    return '₽';
                case 'usd':
                    return '$';
                case 'aed':
                    return 'AED';
                default:
                    return symbolStore;
            }
        },
    },
};
</script>

<style lang="scss">
.price-token-table {
    font-weight: 500;
}
</style> -->

<template>
    <tr>
        <td>
            <div class="jetton-img-main-head">
                <div>
                    <ui-round-image class="jetton-list__icon" size="tiny" v-bind:src="wallet.jetton_meta.image.w72"/>
                </div>
                <div>
                    <ui-link v-bind:to="{ name: 'jetton', params: { address: wallet.jetton_address }}" class="jetton-list-link">
                        {{ wallet.jetton_meta.name || wallet.jetton_address }}
                    </ui-link>
                    <div class="jetton-price-jetton-bottom muted">{{ wallet.jetton_meta.symbol }}</div>
                </div>
            </div>
        </td>
        <td>
            <b>{{ $ton(wallet.balance, wallet.jetton_meta.decimals) }}</b>
            <span style="opacity: .5">{{ wallet.jetton_meta.symbol }}</span>
            <div class="jetton-price-jetton-bottom muted">
                <ui-format-price v-bind:price="getJettonRate(wallet) || 0" v-bind:symbol="'$'"/>
            </div>
        </td>
        <td class="ui-table__cell--align-right">
            <ui-address v-bind:address="wallet.address"/>
        </td>
    </tr>
</template>

<script>
import { formatTons } from '~/helpers.js';

export default {
    props: {
        wallet: Object,
        rates: Array,
    },

    methods: {
        getJettonRate(wallet) {
            const jettonsCount = parseFloat(formatTons(wallet.balance, wallet.jetton_meta.decimals).replace(/\s/g, '').replace(',', '.'));

            const rate = this.rates.filter(({ id }) => id === wallet.jetton_address);

            if (rate.length > 0) {
                return rate[0].price * jettonsCount;
            }

            return null;
        },
    },
};
</script>

<style lang="scss">
.price-token-table {
    font-weight: 500;
}

.jetton-price-jetton-bottom {
    font-size: 13px;
    margin-top: 2px;
}

.jetton-img-main-head {
    display: flex;
    align-items: center;
}
</style>
