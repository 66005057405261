<template>
    <tr class="jetton-link" v-on:click="$router.push({
        name: 'jetton',
        params: {
            address: address,
            skeletonHint: 'jetton'
        }
    })">
        <td class="muted">{{ index }}</td>
        <td class="jetton-image">
            <ui-round-image class="jetton-indexer-table__icon" size="tiny" v-bind:src="img" />
        </td>
        <td class="jetton-title">
            <div class="jetton-title-items">
                <div class="jetton-name">
                    <span>
                        {{ !isMobile ? name : '' }}
                    </span>
                    <span>
                        {{ isMobile ? symbol : '' }}
                    </span>
                </div>
                <div class="jetton-ticker">
                    <template v-if="isMobile">
                        <span v-if="marketCap != 0" class="jetton-cap muted">
                            {{ marketCap }}
                        </span>
                        <span v-else class="muted">—</span>
                    </template>
                    <template v-else>
                        {{ symbol }}
                    </template>
                </div>
            </div>
        </td>
        <td class="jetton-price">
            <p>
                <ui-format-price v-bind:price="price" />
            </p>
            <p>
                <template v-if="isMobile">
                    <percent-change v-bind:change="priceChanges.day" />
                </template>
                <!-- <template v-else>
                    <span class="muted">{{ $ton(price) }} TON</span>
                </template> -->
            </p>
        </td>
        <td class="jetton-hour" v-if="!isMobile">
            <percent-change v-bind:change="priceChanges.hour" />
        </td>
        <td class="jetton-day" v-if="!isMobile">
            <percent-change v-bind:change="priceChanges.day" />
        </td>
        <td class="jetton-week" v-if="!isMobile">
            <percent-change v-bind:change="priceChanges.week" />
        </td>
        <td class="jetton-mc" v-if="!isMobile">
            <span v-if="marketCap != 0">$ {{ marketCap }}</span>
            <span v-else class="muted">—</span>
        </td>
        <td class="jetton-volume" v-if="!isMobile">
            <ui-format-price
                v-if="marketCap != 0"
                v-bind:price="volume"
                v-bind:format="true"
            />
            <template v-else>
                <p class="muted">—</p>
            </template>
        </td>
        <td class="jetton-last" v-if="!isMobile">
            <svg-chart
                v-bind:chartData="chartLast7d"
                v-bind:index="index"
                v-bind:change="priceChanges.week"
            />
        </td>

    </tr>
</template>

<script>
// import MiniChart from '~/lib/Chart.js/UiChartMini.vue';
import { prefixNumber } from '~/lib/Chart.js/helpers.js';
import { JETTON_INDEXER_API_ENDPOINT } from '~/config.js';
import PercentChange from './PercentChange.vue';
import SvgChart from './Chart/svgChart.vue';

export default {
    props: {
        index: {
            type: Number,
        },
        img: {
            type: String,
        },
        name: {
            type: String,
        },
        address: {
            type: String,
        },
        symbol: {
            type: String,
        },
        price: {
            type: Number,
        },
        volume: {
            type: Number,
        },
        chartData: {
            type: String,
        },
        priceChanges: {
            type: Object,
        },
        capitalization: {
            type: Number,
        },
        chartLast7d: {
            type: Array,
        },
    },

    data() {
        return {
            labels: undefined,
            datasets: undefined,
            marketCap: undefined,
            chartLoaded: false,
        };
    },

    computed: {
        showChart() {
            return this.chartLoaded;
        },
    },

    mounted() {
        this.prepareData();
    },

    methods: {
        prepareData() {
            this.marketCap = prefixNumber(this.capitalization);
        },

        chartSVGImgLink() {
            return `${JETTON_INDEXER_API_ENDPOINT}/charts/${this.address}/sparkline.svg`;
        },
    },

    components: {
        // MiniChart,
        PercentChange,
        SvgChart,
    },
};
</script>

<style lang="scss">
.jetton-link {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    cursor: pointer;
}

.jetton-cap {
    font-weight: 400;
}

.jetton-link:hover {
    background: var(--body-light-muted-color);
}

.jetton-name,
.jetton-ticker {
    font-weight: 500;
    word-break: normal;
    max-width: 130px;
    overflow-wrap: break-word;
    white-space: normal;
}

.jetton-title-items {
    display: flex;
    align-items: center;
    gap: 10px;
}

.jetton-title {
    padding: 0 12px 0 0!important;
}

.jetton-name {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jetton-image {
    padding: 0 12px !important;
    width: 24px;
}

.jetton-price {
    text-align: right;
    white-space: nowrap;
}

.jetton-ticker {
    font-weight: 400;
    color: var(--body-muted-text-color);
}

.jetton-hour {
    text-align: center;
    padding-left: 30px;
}

.jetton-day {
    text-align: center;
}

.jetton-week {
    text-align: center;
}

.jetton-mc {
    text-align: center;
}

.jetton-volume {
    text-align: center;
}

.jetton-last {
    width: 280px;

    .svg-chart {
        margin: 12px 0;
        height: 50px;

        &__skeleton {
            border-radius: 9px;
            height: 52px;
        }

        &--green {
            stroke: #3fb950;
            fill: #3fb950;
            color: #3fb950;
        }

        &--red {
            stroke: #f74b4c;
            fill: #f74b4c;
            color: #f74b4c;
        }
    }
}
</style>
