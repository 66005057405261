<template>
    <div class="svg-chart">
        <svg ref="chart" width="200" height="50"></svg>
    </div>
</template>

<script>
import * as d3 from 'd3';

export default {
    props: {
        chartData: {
            type: Array,
            required: true,
        },
        change: {
            type: Number,
            required: true,
        },
    },
    mounted() {
        this.drawChart();
    },
    methods: {
        drawChart() {
            const data = this.chartData.map(d => ({
                date: new Date(d.date),
                value: +d.value,
            }));

            const svg = d3.select(this.$refs.chart);

            const width = +svg.attr('width');
            const height = +svg.attr('height');

            const isIncreasing = this.change >= 0;

            // const lineColor = isIncreasing ?
            //     'var(--chart-line-mini-color)' : 'var(--chart-line-mini-red-color)';
            // const gradientColors = isIncreasing ?
            //     {
            //         primary: 'var(--chart-line-mini-opacity-color)',
            //         secondary: 'var(--chart-line-mini-green-secondary-color)',
            //     } : {
            //         primary: 'var(--chart-line-mini-opacity-red-color)',
            //         secondary: 'var(--chart-line-mini-red-secondary-color)',
            //     };

            const lineColor = isIncreasing
                ? 'var(--chart-line-green-color)' : 'var(--chart-line-mini-red-color)';

            // const gradientColors = isIncreasing
            //     ? {
            //         primary: 'var(--chart-line-green-opacity-color)',
            //         secondary: 'var(--chart-line-green-secondary-color)',
            //     } : {
            //         primary: 'var(--chart-line-mini-opacity-red-color)',
            //         secondary: 'var(--chart-line-mini-red-secondary-color)',
            //     };

            const yOffset = 2;

            const xScale = d3.scaleTime()
                .domain(d3.extent(data, d => d.date))
                .range([0, width]);

            const yScale = d3.scaleLinear()
                .domain([
                    d3.min(data, d => d.value) * 0.95,
                    d3.max(data, d => d.value),
                ])
                .range([height - yOffset, yOffset]);

            const line = d3.line()
                .x(d => xScale(d.date))
                .y(d => yScale(d.value))
                .curve(d3.curveBasis);

            const gradientId = `line-gradient-${Math.random().toString(36).substr(2, 9)}`;

            // const gradient = svg.append('defs')
            //     .append('linearGradient')
            //     .attr('id', `${gradientId}`)
            //     .attr('gradientUnits', 'userSpaceOnUse')
            //     .attr('x1', 0)
            //     .attr('y1', 0)
            //     .attr('x2', 0)
            //     .attr('y2', height)
            //     .selectAll('stop')
            //     .data([
            //         { offset: '0%', color: gradientColors.primary, opacity: 0 },
            //         { offset: '100%', color: gradientColors.secondary, opacity: 0 },
            //     ])
            //     .enter()
            //     .append('stop')
            //     .attr('offset', d => d.offset)
            //     .attr('stop-color', d => d.color)
            //     .attr('stop-opacity', d => d.opacity);

            svg.append('path')
                .datum(data)
                .attr('fill', 'none')
                .attr('stroke', lineColor)
                .attr('stroke-width', 2)
                .attr('d', line);

            svg.append('path')
                .datum(data)
                .attr('fill', `url(#${gradientId})`)
                .attr('stroke', 'none')
                .attr('d', d3.area()
                    .x(d => xScale(d.date))
                    .y0(height)
                    .y1(d => yScale(d.value))
                    .curve(d3.curveBasis));
        },
    },
};
</script>

<style>
.svg-chart {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
