<template>
    <div class="widget">
        <div class="widget-content widget-channel-container" v-if="newsData.length > 0">
            <news-feed-item v-for="(news, index) in newsData" v-bind:key="`news-id-${index}`" v-bind:id="news.id"
                v-bind:channelId="news.channelId" v-bind:channelUsername="news.channelUsername" v-bind:channelName="news.channelName" v-bind:text="news.text" v-bind:date="news.date" />
            <!-- <ui-mugen-scroll v-bind:handler="getNewsInfo" v-bind:shouldHandle="shouldHandleScroll"
                v-bind:showButton="showPreloader" v-bind:isLoading="isLoading" /> -->
        </div>
        <div class="widget-content widget-channel-container" v-else>
            <news-feed-skeleton v-for="i in 4" v-bind:key="`news-skeleton-${i}`" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getNews } from '~/api/news.js';
import NewsFeedItem from './NewsFeedItem.vue';
import NewsFeedSkeleton from './NewsFeedSkeleton.vue';

export default {
    data() {
        return {
            newsData: [],
            ruNews: [],
            enNews: [],
            isLoading: true,
            // hasMore: true,
        };
    },

    computed: {
        shouldHandleScroll() {
            return this.isLoading
                && this.hasMore
                && this.newsData?.length > 0;
        },

        showPreloader() {
            return this.newsData?.length > 0
                && this.hasMore;
        },

        ...mapState({ newsLanguage: 'appLocale' }),
    },

    watch: {
        '$i18n.locale': function () {
            this.hasMore = true;
            this.getNewsInfo();
        },
        ruNews() {
            this.getNewsInfo();
        },
        enNews() {
            this.getNewsInfo();
        },
        // newsLanguage: {
        //     immediate: false,
        //     handler() {
        //         this.hasMore = true;
        //         this.newsData = [];
        //         this.getNewsInfo();
        //     },
        // },
    },

    mounted() {
        this.getRuNews();
        this.getEnNews();
    },

    methods: {
        async getRuNews() {
            try {
                const limit = 24;
                const channels_ru = [1433990798, 2020833003, 1444152677];
                const ruResponse = await getNews(channels_ru, limit);
                // console.log('Ru News:', ruResponse);
                this.ruNews = ruResponse;
            } catch (error) {
                console.error(error);
            }
        },
        async getEnNews() {
            try {
                const limit = 24;
                const channels_en = [1191264954, 1744725639, 1379327992, 1497424960];
                const enResponse = await getNews(channels_en, limit);
                // console.log('En News', enResponse);
                this.enNews = enResponse;
            } catch (error) {
                console.error(error);
            }
        },
        async getNewsInfo() {
            const lang = this.$i18n.locale;
            try {
                // const limit = 24;
                // const offset = this.newsData[0]?.id - this.newsData?.length || 0;
                // const channels_ru = [1433990798, 2020833003, 1444152677];
                // const channels_en = [1191264954, 1744725639, 1379327992, 1497424960];
                // this.ruNews = await getRuNews(channels_ru, limit);
                // this.enNews = await getEnNews(channels_en, limit);
                const newsByLang = {
                    ru: this.ruNews,
                    en: this.enNews,
                };

                // const newsByLang = {
                //     'ru': await getRuNews(channels_ru, limit),
                //     'en': await getEnNews(channels_en, limit),
                // };
                const getChannelsData = newsByLang[lang].channels;
                const getNewsData = newsByLang[lang].posts;
                const parsedData = getNewsData.map((item) => {
                    const channelInfo = getChannelsData[item.channel_id];
                    return {
                        channelId: item.channel_id,
                        channelUsername: channelInfo.username,
                        channelName: channelInfo.title,
                        id: item.post_id,
                        date: item.date,
                        text: item.text,
                    };
                });
                this.newsData = parsedData;
                if (parsedData.length < 8) this.hasMore = false;
            } catch (error) {
                console.error(error);
            }
        },
    },

    components: {
        NewsFeedItem,
        NewsFeedSkeleton,
    },
};
</script>

<style lang="scss">
.widget-channel-container {
    max-height: 600px;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .widget {
        width: 100%;
    }
}
</style>
