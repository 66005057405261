<template>
    <div class="card-market-box">
        <div class="card-market-header">
            <div class="card-market-title">
                <span>{{ $t('market_blocks.headers.marketcap') }}</span>
            </div>
        </div>
        <div class="card-market-cap">
            <span v-if="marketcap">${{ marketcap }}</span>
            <span class="skeleton" v-else>$284753.20</span>
        </div>
        <div class="card-market-body">
            <mini-chart v-bind:labels="labels" v-bind:datasets="datasets" />
        </div>
    </div>
</template>

<script>
import MiniChart from '~/lib/Lightchart/UiChartMini.vue';
import { getMarketCapInfo } from '~/api/jettonIndexer.js';
import { prefixNumber } from '~/lib/Chart.js/helpers.js';

export default {
    data() {
        return {
            labels: undefined,
            datasets: undefined,
            marketcap: undefined,
        };
    },

    mounted() {
        this.getMarketInfo();
    },

    methods: {
        async getMarketInfo() {
            const getChart = await getMarketCapInfo();
            const chartData = getChart.chart;

            const labels = chartData.map(data => Number(data.timestamp));

            const dataset = Object.freeze({
                data: chartData.map(data => data.value),
            });

            this.labels = labels;
            this.datasets = [dataset];
            this.marketcap = prefixNumber(getChart.current_value / 10 ** 9);
        },
    },

    components: {
        MiniChart,
    },
};
</script>

<style lang="scss" scoped></style>
