import { JETTON_INDEXER_API_ENDPOINT } from '~/config.js';
import axios from 'axios';

const http = axios.create({
    baseURL: JETTON_INDEXER_API_ENDPOINT,
});

export const getJettonIndexerInfo = async function (address, days = 1) {
    const { data: result } = await http.get('/jetton', {
        params: {
            address,
            days,
        },
    });

    return result;
};

export const getJettonMarketInfo = async function (category = 'all', offset = 0, limit = 50) {
    const { data: result } = await http.get('/jettons', {
        params: {
            category,
            offset,
            limit,
        },
    });

    return result;
};

export const getJettonDexPairs = async function (address) {
    const { data: result } = await http.get(`/dexes/${address}`);

    return result;
};

export const getMarketVolumeInfo = async function () {
    const { data: result } = await http.get('/stats/volume');

    return result;
};

export const getMarketCapInfo = async function () {
    const { data: result } = await http.get('/stats/mc');

    return result;
};

export const getMarketTVLInfo = async function () {
    const { data: result } = await http.get('/stats/tvl');

    return result;
};

export const getMarketOverviewInfo = async function () {
    const { data: result } = await http.get('/stats/market');

    return result;
};

export const getTrendingJettonsInfo = async function () {
    const { data: result } = await http.get('/stats/trending');

    return result;
};
