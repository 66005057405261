<template>
    <div class="widget">
        <div class="widget-header">
            <div class="widget-header-item">
                <div class="widget-type">
                    {{$t('indexpage.ton_price')}}
                </div>
                <div class="widget-price">
                    <!-- <ui-fiat v-if="price" v-bind:tonValue="10 ** 9" />  -->
                    <span v-if="price">${{ price.toFixed(2) }}</span>
                    <span class="skeleton" v-else>NNNNN</span>
                </div>
            </div>
            <div class="widget-header-item">
                <div class="widget-type widget-type-market-cap">
                    {{$t('indexpage.market_overview')}}
                </div>
                <div class="widget-price widget-price-market-cap">
                    <span v-if="marketcap">${{ marketcap }}</span>
                    <span class="skeleton" v-else>NNNNN</span>
                </div>
            </div>
        </div>
        <ui-link v-bind:to="{ name: 'stats' }" class="widget-content">
            <mini-chart
                v-bind:labels="labels"
                v-bind:datasets="datasets"
            />
        </ui-link>
    </div>
</template>

<script>
import MiniChart from '~/lib/Lightchart/UiChartMini.vue';
// import MiniChart from '~/lib/Chart.js/UiChartMini.vue';
import { getMarketDataHistory } from '~/api/coingecko.js';
import { prefixNumber } from '~/lib/Chart.js/helpers.js';
import { getBlockchainMarketAnal } from '~/api/extenderContracts.js';

export default {
    data() {
        return {
            labels: undefined,
            datasets: undefined,
            marketcap: null,
            price: null,
        };
    },

    mounted() {
        // this.getMarketPrice();
        this.loadData();
    },

    computed: {
        parsedChartLabels() {
            if (!this.labels) {
                return undefined;
            }

            return this.labels;
        },

        parsedChartDatasets() {
            if (!this.datasets) {
                return undefined;
            }

            return this.datasets;
        },
    },

    methods: {
        async getMarketPrice() {
            try {
                const getMarketPrice = await getBlockchainMarketAnal();
                this.price = getMarketPrice.quotes.usd.price;
                this.marketcap = prefixNumber(getMarketPrice.quotes.usd.market_cap, this.$i18n.locale);
            } catch (error) {
                console.error(error);
            }
        },

        async loadData() {
            try {
                const {
                    prices,
                    market_caps,
                } = await getMarketDataHistory({
                    currency: 'USD',
                    days: 14,
                    interval: 'daily',
                });

                // Set number values
                this.marketcap = prefixNumber(market_caps.at(-1)[1], this.$i18n.locale);
                this.price = prices.at(-1)[1];

                const chartData = prices.map(el => el[1]);

                const labels = prices.map(([timestamp, _]) => timestamp);
                const border_color = chartData[0] <= chartData[chartData.length - 1] ? '#3fb950' : '#f74b4c';

                const dataset = Object.freeze({
                    data: chartData,
                    borderWidth: 1.5,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    pointHitRadius: 0,
                    fill: true,
                    borderColor: border_color,
                    backgroundGradient: [`${border_color}50`, `${border_color}20`, `${border_color}00`],
                });

                this.labels = labels;
                this.datasets = [dataset];
            } catch (error) {
                console.error(error);
            }
        },
    },

    components: {
        MiniChart,
    },
};
</script>

<style lang="scss">
.chart-bar-mini {
    margin-top: 20px;
}
.chart-preloader {
    position: relative;
    bottom: -35px;
    svg {
        fill: var(--body-light-muted-color);
    }
}
.widget-content {
    canvas {
        cursor: pointer;
        transition: .4s;
    }
}
.widget-content:hover {
    text-decoration: none;
}
.chart-bar-mini__graph{
    height: 110px!important;
    max-height: 110px!important;
    padding-bottom: 2px!important;
}
.widget-type-market-cap, .widget-price-market-cap {
    text-align: right;
}
.widget-type {
    text-transform: uppercase;
    font-weight: 500;
}
</style>
